import { CheckCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { SignatureRequest } from "../types";

function Confirmation(props: { signatureRequest: SignatureRequest }) {
  const { signatureRequest } = props;
  const { t } = useTranslation();
  const branding = signatureRequest?.branding;

  return (
    <div className="fixed flex flex-col w-full h-full">
      <div className={`flex flex-col items-center justify-center gap-4 h-full p-7 lg:p-0 ${branding?.colour ? '' : "bg-gray"}`}>
        {branding?.colour && <div className="h-screen w-screen fixed -z-10 opacity-5" style={{ backgroundColor: branding.colour }} />}
        <div className="rounded-2xl bg-white lg:shadow max-w-lg w-full p-8">
          <div className="flex flex-col gap-3.5 lg:gap-0 lg:flex-row items-center w-full py-4 px-8 z-10 mb-6 bg-[#06AB691F] text-green rounded-lg">
            <div className="flex items-center">
              <CheckCircleIcon className="w-5 h-5 mr-1" />
              <p className="font-semibold mr-3">{t("Success!")}</p>
            </div>
            <p className="font-medium text-center lg:text-left">{t("You've signed your documents.")}</p>
          </div>

          {branding?.logo
            ? <img src={branding?.logo} className="mb-4 mx-auto" width={160} alt="Brand Logo" />
            : <img src="https://static.portant.co/logo-full-blue.png" className="mb-4 mx-auto" width={160} alt="Portant Logo" />
          }

          <h2 className="text-blue font-sans text-xl font-semibold text-center mb-2">
            {t("Signing complete")}
          </h2>
          <h3 className="text-black font-sans text-center">
            {t("You will receive a copy in your inbox shortly")}
          </h3>

          <a className="btn bg-[#F6F6F6] w-full rounded-full mt-5 py-2.5 shadow-none" href="https://www.gmail.com" target="_blank" rel="noreferrer">
            <img src="https://storage.googleapis.com/static.portant.co/gmail-icon.svg" className="h-6 mr-1" alt="gmail" />
            {t("Open Gmail")}
          </a>
        </div>
        <div className="rounded-2xl bg-white lg:shadow max-w-lg w-full p-8">
          <h2 className="text-blue font-sans text-xl font-semibold text-center mb-2">
            {t("Get your own documents eSigned")}
          </h2>
          <h3 className="text-black font-sans text-center mb-4">
            {t("Design your documents in Google Docs & Slides, then get them eSigned effortlessly with Portant.")}
          </h3>
          <a
            href="https://app.portant.co?utm_source=document-signing&utm_medium=confirmation-page&utm_campaign=signup-from-recipients"
            className="btn btn-primary rounded-full w-60 mx-auto py-2.5 shadow-none text-center"
            target="_blank"
            rel="noreferrer"
          >
            {t("Start now")}
          </a>

          <div className="flex items-center justify-center gap-2 mt-2">
            <p className="font-sans text-blue">{t("Have an account?")}</p>
            <a
              href="https://app.portant.co"
              target="_blank"
              rel="noreferrer"
              className="font-sans text-blue font-semibold"
            >
              {t("Log in")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation;
