import PageNotFound from "./PageNotFound";
import PageAlreadySigned from "./PageAlreadySigned";
import PageOverlay from "./PageOverlay";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import { SignatureRequest, SignatureRequestError } from "../types";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import { DocumentContext, ModalContext, GlobalStateContext, SignatureRequestContext } from "../contexts";
import { GlobalStateActionType, GlobalStateReducer } from "../reducers";
import SignatureModal from "./SignatureModal";
import fontkit from '@pdf-lib/fontkit'
import Confirmation from "./Confirmation";
import { useWindowProperties } from "../customHooks";
import ContinueModal from "./ContinueModal";
import AppLanding from "./AppLanding";
import PageCanceled from "./PageCanceled";
import PageExpired from "./PageExpired";
import checkIcon from "../checkIcon";
import ManageRequestsModal from "./ManageRequestsModal";
import PreviewPageOverlay from "./PreviewPageOverlay";
import MainSideCard from "./MainSideCard";
import PageThumbnails from "./PageThumbnails";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function App() {
  const token = window.location.pathname.split("/")[1];
  const windowProperties = useWindowProperties();
  const isDesktop = windowProperties.width >= 1024;

  const [isLoading, setIsLoading] = useState({ requests: true, mainDocument: true, thumbnailDocument: isDesktop });
  const [signatureRequest, setSignatureRequest] = useState<SignatureRequest>();
  const [error, setError] = useState<SignatureRequestError | undefined>();

  const isPreview = signatureRequest?.recipientNumber === 0;

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const pageContainerRef = useRef<HTMLDivElement>(null);

  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const signableDocument = signatureRequest?.signableDocuments[currentDocumentIndex] ?? null;

  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();

  const [currentRecipientNumber, setCurrentRecipientNumber] = useState<number>(1);

  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
  const [showContinueModal, setShowContinueModal] = useState<boolean>(false);
  const [showManageRequestsModal, setShowManageRequestsModal] = useState<boolean>(false);

  const [globalState, dispatch] = useReducer(GlobalStateReducer, { status: "INITIAL", fieldValues: {} });

  const pageNumbers: Array<number> = Array(pdfDocument?.numPages ?? 0).fill(1).map((one: number, i: number) => one + i);
  const pageWidth = Math.min(800, windowProperties.width);
  const branding = signatureRequest?.branding;

  const calculateVisibility = () => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    let maxVisible = 0;
    let visiblePage = null;

    const pages = scrollContainer.querySelectorAll('.page');
    pages.forEach((page, index) => {
      const rect = page.getBoundingClientRect();
      const containerRect = scrollContainer.getBoundingClientRect();

      const visibleHeight = Math.min(containerRect.bottom, rect.bottom) - Math.max(containerRect.top, rect.top);
      const visibleWidth = Math.min(containerRect.right, rect.right) - Math.max(containerRect.left, rect.left);

      if (visibleHeight > 0 && visibleWidth > 0) {
        const visibleArea = visibleHeight * visibleWidth;
        if (visibleArea > maxVisible) {
          maxVisible = visibleArea;
          visiblePage = index + 1;
        }
      }
    });

    if (visiblePage) {
      setSelectedPage(visiblePage);
    }
  };


  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', calculateVisibility);

    return () => {
      container.removeEventListener('scroll', calculateVisibility);
    };
  }, [pageNumbers]);


  const initialFetch = useCallback(async (abortController: AbortController) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${token}/`, { signal: abortController.signal })
      if (res.ok) setSignatureRequest(await res.json());
      else setError(await res.json());
    } catch (err) {
      console.error(err);
    }

    try {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${token}/access/`, { method: "POST", signal: abortController.signal });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, requests: false }));
    }
  }, [token]);

  useEffect(() => {
    if (!token)
      return setIsLoading((prev) => ({ ...prev, requests: false }));
    const abortController = new AbortController();
    initialFetch(new AbortController());
    return () => abortController.abort();
  }, [token, initialFetch]);

  useEffect(() => {
    document.title = signableDocument?.documentName ?? "Portant Sign"
  }, [signableDocument])

  async function generateSignedPdf() {
    if (!globalState.signatureData || !pdfDocument || !signableDocument)
      throw new Error("Unable to generate signed PDF without signature data.");

    const data = await pdfDocument.getData();
    const signedPdfDocument = await PDFDocument.load(data as any);

    const pdfSignatureImage = await signedPdfDocument.embedPng(globalState.signatureData);
    const checkImage = await signedPdfDocument.embedPng(checkIcon);

    signedPdfDocument.registerFontkit(fontkit)
    const fontUrl = process.env.PUBLIC_URL + "/fonts/Eina02-Regular.ttf";
    const fontData = await fetch(fontUrl).then(res => res.arrayBuffer());
    const eina02Font = await signedPdfDocument.embedFont(fontData);

    signableDocument.fieldRegions
      .filter(r => !signatureRequest!.recipientNumber || r.recipient === signatureRequest!.recipientNumber)
      .forEach((region, index) => {
        const page = signedPdfDocument.getPage(region.pageNumber - 1); // -1 for 0-based index
        if (region.type === "SIGNATURE") {
          page.drawImage(pdfSignatureImage, {
            x: region.bbox.x0,
            y: page.getMediaBox().height - region.bbox.y1,
            width: region.bbox.x1 - region.bbox.x0,
            height: region.bbox.y1 - region.bbox.y0
          });

        } else if (region.type === "DATE") {
          const dateString = globalState.signatureDateString!;

          page.drawText(dateString, {
            x: region.bbox.x0,
            y: page.getMediaBox().height - region.bbox.y1,
            size: 12,
            font: eina02Font
          });

        } else if (region.type === "TEXT") {
          const text = globalState.fieldValues[region.id] ?? "";

          page.drawText(text, {
            x: region.bbox.x0,
            y: page.getMediaBox().height - region.bbox.y1 + 12 - 4, // font size - padding
            size: 12,
            font: eina02Font,
          });
        } else if (region.type === "INITIALS") {
          const initials = globalState.fieldValues[region.id] ?? "";

          page.drawText(initials, {
            x: region.bbox.x0,
            y: page.getMediaBox().height - region.bbox.y1 + 12 - 4, // font size - padding
            size: 12,
            font: eina02Font,
          });

        } else if (region.type === "CHECKBOX") {
          const checked = !!globalState.fieldValues[region.id];
          if (!checked)
            return

          page.drawImage(checkImage, {
            x: region.bbox.x0 + 2,
            y: page.getMediaBox().height - region.bbox.y1 + 2,
            width: region.bbox.x1 - region.bbox.x0 - 4,
            height: region.bbox.y1 - region.bbox.y0 - 4
          });
        }
      });

    const signedPdfData = await signedPdfDocument.save();
    return signedPdfData;
  }

  function handleClickEdit() {
    dispatch({ type: GlobalStateActionType.RESET });
    setShowSignatureModal(true);
  }

  function handlePageLoad(page: PDFPageProxy) {
    if (page.pageNumber === pageNumbers.length) {
      setIsLoading((prev) => ({ ...prev, mainDocument: false }));
    }
  }


  async function handleClickAgree() {
    dispatch({ type: GlobalStateActionType.SET_STATUS, status: "SUBMITTING" });

    const signedPdfData = await generateSignedPdf();

    const uploadUrlData = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${signatureRequest!.publicToken}/generate-upload-url/`, {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        signableDocumentId: signableDocument!.id
      })
    }).then(res => res.json());

    const uploadUrl = uploadUrlData["url"];
    const uploadResponse = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "content-type": "application/pdf"
      },
      body: signedPdfData
    });

    const nextDocumentIndex = currentDocumentIndex + 1;

    if (nextDocumentIndex === signatureRequest!.signableDocuments.length) {
      const status = uploadResponse.status === 200 ? "SUCCESS" : "ERROR";
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/signatures/${signatureRequest!.publicToken}/confirm-upload-status/`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ signableDocumentId: signableDocument!.id, status })
      });

      dispatch({ type: GlobalStateActionType.SET_STATUS, status: "SUBMITTED" });

      if (signatureRequest!.redirectUrl)
        window.location.href = signatureRequest!.redirectUrl;
    } else {
      setCurrentDocumentIndex(nextDocumentIndex);
      dispatch({ type: GlobalStateActionType.RESET });
    }
  }

  if (isLoading.requests || (globalState.status === "SUBMITTED" && signatureRequest?.redirectUrl))
    return (
      <div className="fixed flex w-full h-full bg-gray">
        <img src="https://static.portant.co/portant-loading-blue.svg" className="w-32 h-32 m-auto" alt="Loading Logo" />
      </div>
    );

  if (!token)
    return <AppLanding />

  if (error && error.detail === "The signature request has expired.")
    return <PageExpired />

  if (!signatureRequest)
    return <PageNotFound />

  if (signatureRequest?.status === "CANCELED" || signableDocument === null)
    return <PageCanceled />

  if (signatureRequest?.status === "COMPLETED")
    return <PageAlreadySigned />

  if (globalState.status === "SUBMITTED")
    return <Confirmation signatureRequest={signatureRequest} />

  return (
    <SignatureRequestContext.Provider value={signatureRequest}>
      <ModalContext.Provider value={{ openModal: () => isPreview ? setShowManageRequestsModal(true) : setShowSignatureModal(true), closeModal: () => isPreview ? setShowManageRequestsModal(false) : setShowSignatureModal(false), setRecipientModal: (n) => setCurrentRecipientNumber(n) }}>
        <GlobalStateContext.Provider value={{ state: globalState, dispatch }}>
          <SignatureModal visible={showSignatureModal} fieldRegions={signableDocument.fieldRegions} />
          {(isLoading.mainDocument || isLoading.thumbnailDocument) && (
            <div className="fixed flex w-screen h-screen z-50 bg-gray">
              <img src="https://static.portant.co/portant-loading-blue.svg" className="w-32 h-32 m-auto" alt="Loading Logo" />
            </div>
          )}
          <ContinueModal visible={showContinueModal} closeModal={() => setShowContinueModal(false)} onContinue={handleClickAgree} />
          <ManageRequestsModal visible={showManageRequestsModal} closeModal={() => setShowManageRequestsModal(false)} recipientNumber={currentRecipientNumber} />
          <div className={`fixed flex flex-col w-screen h-screen justify-center items-center overflow-auto ${branding?.colour ? "" : "bg-[#F6FAFF]"}`} ref={scrollContainerRef}>
            {branding?.colour && <div className="h-screen w-screen fixed -z-10 opacity-5" style={{ backgroundColor: branding.colour }} />}
            <div className="flex gap-6 h-full w-full px-10 justify-center pt-8 lg:py-24">
              <DocumentContext.Provider value={{ signatureRequest, signableDocument, pdfDocument }}>
                {isDesktop && (
                  <div className="w-36 relative">
                    <PageThumbnails
                      pageNumbers={pageNumbers}
                      pageWidth={pageWidth}
                      selectedPage={selectedPage}
                      setSelectedPage={setSelectedPage}
                      onLoadSuccess={() => setIsLoading((prev) => ({ ...prev, thumbnailDocument: false }))}
                      signableDocument={signableDocument}
                    />
                  </div>
                )}

                <Document file={signableDocument!.documentUrl} onLoadSuccess={(doc) => setPdfDocument(doc)} className="flex-shrink-0" loading={<div className="bg-white rounded overflow-hidden shadow h-full" style={{ width: pageWidth }} />}>
                  <div className="relative flex flex-col items-center pb-80 lg:pb-24 gap-3.5" ref={pageContainerRef}>
                    {pageNumbers.map((n: number) =>
                      <div key={n} className="relative">
                        <Page pageNumber={n} width={pageWidth - 40} className={`rounded shadow overflow-hidden main-page-${n} page`} renderTextLayer={false} renderAnnotationLayer={false} loading={<div className="opacity-0" style={{ width: `${pageWidth}px` }} />} onLoadSuccess={handlePageLoad} />
                        {signatureRequest?.recipientNumber === 0
                          ? <PreviewPageOverlay pageNumber={n} width={pageWidth - 40} />
                          : <PageOverlay pageNumber={n} width={pageWidth - 40} />
                        }
                      </div>
                    )}
                  </div>
                </Document>
              </DocumentContext.Provider>
              <div className="absolute lg:relative lg:w-[356px]">
                <div className="fixed z-10 top-0 lg:py-24 lg:h-full lg:w-[356px] flex flex-col justify-between items-end">
                  <MainSideCard
                    currentDocumentIndex={currentDocumentIndex}
                    handleClickAgree={handleClickAgree}
                    handleClickEdit={handleClickEdit}
                    isPreview={isPreview}
                    setShowSignatureModal={setShowSignatureModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </GlobalStateContext.Provider>
      </ModalContext.Provider>
    </SignatureRequestContext.Provider>
  );
}

export default App;
